import React from 'react';
import ImageComponent3 from './ImageComponent3';

export default function StoriesMain() {
  return (
    <div>
      <div className='md:mt-[10vh] mt-24 md:h-screen'>
        <div className=' bg-[#f7f3ea] relative overflow-hidden mx-auto my-0'>
        </div>
        <div className="flex lg:flex-row mt-[2vw] ml-[2vw] max-md:mr-[2vw] flex-col max-lg:justify-center mx-auto  max-lg:items-center overflow-x-scroll custom-scrollbar">
        <ImageComponent3 date={"20 February 2025"} image={`The 8-Step Modular Process at Vikas Plywood`} text={"The 8-Step Modular Process at Vikas Plywood"} />
        <ImageComponent3 date={"8 November 2024"} image={`VIKAS PLYWOOD The Wooden Wonderland`} text={"VIKAS PLYWOOD: The Wooden Wonderland"} />
        <ImageComponent3 date={"22 August 2024"} image={`Why Pre-polished Veneers are the future`} text={"Why Pre-polished Veneers are the future?"} />
          <ImageComponent3 date={"10 June 2024"} image={`AGT Wall Panels`} text={"AGT Wall Panels: Outshining Industry Alternatives with Unrivaled Quality"} />
          <ImageComponent3 date={"12 May 2024"} image={`Veneer vs Laminate`} text={"Veneer vs Laminate: Understanding the Distinctions"} />
          <ImageComponent3 date={"28 April 2024"} image={`Enhancing Your Home`} text={"Enhancing Your Home With Exquisite Laminated Wooden Flooring"} />

        </div>
        <div className="flex my-[2vw] ml-[15px] md:ml-[2vw] items-center ">
          <h1 className="[font-family:'PP_Hatton-medium',Helvetica] text-[13vw] sm:text-[55px] font-medium  text-[#000] relative text-left overflow-hidden z-[25] ">
            Vikas Stories
          </h1>

          {/* new chages */}
        </div>
      </div>
    </div>

  );
}
