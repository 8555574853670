import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { BsArrowDown } from 'react-icons/bs';


export const points2 = [
    `Elegance and Versatility:  Wooden floors are available in a wide range of styles, grains, and finishes, making it easy to find the perfect fit for your home's aesthetic.`,
    `Durability:  Hardwood floors are known for their long-lasting quality. When maintained properly, they can stand the test of time, maintaining their beauty for decades.`,
    `Easy Maintenance:  Wooden floors are incredibly easy to clean and maintain. A quick sweep and occasional mopping are all it takes to keep them looking as good as new.`,
    `Budget Friendly:  Laminate wooden floors offer a budget-friendly solution for those seeking the warmth and elegance of wood without the high cost associated with solid hardwood flooring.`
];
export const points5 = [
    `Time-saving:  No more waiting for the polishing process to complete. Your project can be finished faster.`,
    `Consistent finish:  Every panel guarantees a uniform, high-quality look. No more inconsistencies.`,
    `Easy Maintenance:  Clean the sheet with any chemical based cleaner.`,
    `Reduced labor costs: Skip the extra manpower required for on-site polishing.`,
    `Enhanced durability:   The pre-applied finish protects the veneer from scratches and stains.`,
    `Fire retardant:   These veneers incorporate fire-retardant technology for added protection.`,
    `Immediate gratification:   Enjoy the luxurious look and feel of your space right away.`

];
export const points3 = [
    `Oak:  Known for its strength and durability, oak is a timeless choice that complements both traditional and contemporary interiors.`,
    `Maple:  With its fine, straight grain and light colour, maple creates a sense of spaciousness in any room.`,
    `Cherry:  If you seek warmth and richness, cherry wood offers a luxurious touch that exudes elegance.    `,
    `Walnut:  Dark and inviting, walnut wood floors add a touch of luxury and opulence to your space.`
];
export const points4 = [
    `Professional Installation:  Hire experienced installers to ensure a flawless and long-lasting result.        `,
    `Regular Cleaning:  Sweep or vacuum your floors daily to prevent the accumulation of dirt and debris.`,
    `Proper Protection:  Use rugs or mats in high-traffic areas to minimise wear and tear.    `,
    `Refinishing:  Every few years, consider refinishing your floors to keep them looking their best.        `
];


export const data = [
    {
        Aspect: "Material",
        Veneer: "Thin layer of real wood",
        Laminate: "Multiple layers of synthetic materials"
    },
    {
        Aspect: "Appearance",
        Veneer: "Natural wood beauty with grain, crown and burl patterns",
        Laminate: "Mimics various textures and patterns"
    },
    {
        Aspect: "Thickness",
        Veneer: "Comes in 4mm thickness",
        Laminate: "Thickness ranges from 0.72-1 mm"
    },
    {
        Aspect: "Applications",
        Veneer: "Furniture, cabinetry, interior doors, walls, ceilings",
        Laminate: "Countertops, cabinet surfaces, shutters"
    },
    {
        Aspect: "Durability",
        Veneer: "More susceptible to scratches, water stains",
        Laminate: "More durable and resistant to wear"
    },
    {
        Aspect: "Cost",
        Veneer: "Usually more expensive",
        Laminate: "More affordable"
    },
    {
        Aspect: "Aesthetic Preferences",
        Veneer: "Authentic beauty of real wood",
        Laminate: "Broad range of design options"
    },
    {
        Aspect: "Maintenance",
        Veneer: "May require more maintenance",
        Laminate: "Easier to clean and maintain"
    }
];


export const ScrollButton = () => {
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 500) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToContent = () => {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      });
    };
  
    return (
      <div 
        className={`absolute md:right-8 md:bottom-16 bottom-4 right-2 transition-all duration-500 ${
          isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        <button
          onClick={scrollToContent}
          className="group flex flex-col items-center"
        >
          <div className="relative mb-3">
            <span className="text-xs tracking-[0.3em] text-[#fafafa] font-light rotate-90 inline-block transform origin-left">
              SCROLL
            </span>
          </div>
          
          <div className="relative h-16 w-px bg-[#fafafa]/20 overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="w-px h-full bg-[#fafafa] absolute animate-scroll-line" />
            </div>
          </div>
  
          <div className="mt-3 relative">
            <div className="animate-bounce-slow">
              <BsArrowDown 
                size={20}
                className="text-[#fafafa] transform transition-transform duration-300 group-hover:translate-y-1"
              />
            </div>
          </div>
        </button>
  
        <style jsx global>{`
          @keyframes scroll-line {
            0% {
              transform: translateY(-100%);
            }
            100% {
              transform: translateY(100%);
            }
          }
          
          .animate-scroll-line {
            animation: scroll-line 1.5s ease-in-out infinite;
          }
          
          .animate-bounce-slow {
            animation: bounce 2s infinite;
          }
          
          @keyframes bounce {
            0%, 100% {
              transform: translateY(-25%);
              animation-timing-function: cubic-bezier(0.8,0,1,1);
            }
            50% {
              transform: translateY(0);
              animation-timing-function: cubic-bezier(0,0,0.2,1);
            }
          }
        `}</style>
      </div>
    );
  };


  const textVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: { 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" }
      }
    };
  
    const letterVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.01, // Staggered animation
                duration: 0.2,      // Slightly longer duration for smoothness
                ease: "easeInOut", // Use easeInOut for smoother start and end
                type: "spring",   // Add a spring effect for a pleasant bounce
                stiffness: 50,   // Adjust stiffness for the bounce (higher = more stiff)
                damping: 10,      // Adjust damping for the bounce (higher = less bounce)
            },
        }),
    };
    
    export const AnimatedTitle = ({ text }) => {
        const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.05 });
    
        const words = text.split(" ");
    
        return (
            <motion.h1
                ref={ref}
                className="text-white z-40 font-['PP_Hatton-medium'] font-medium text-[27px] text-center p-24 max-md:p-5 md:text-[7vh] leading-tight break-words"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <motion.span
                    initial={{ x: 0, y: 0 }}
                    animate={{ x: 0, y: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ display: "inline-block" }}
                >
                    {words.map((word, wordIndex) => (
                        <motion.span // Animate each WORD, not letter
                            key={wordIndex}
                            custom={wordIndex} // Custom value for each word
                            variants={letterVariants} // Use the same letter variants (or create word variants)
                            initial="hidden"
                            animate={inView? "visible": "hidden"}
                            style={{ display: "inline-block" }} // Needed for transform
                        >
                            {word} {/* Display the whole word */}
                            {wordIndex < words.length - 1 && <span>&nbsp;</span>} {/* Space after word */}
                        </motion.span>
                    ))}
                </motion.span>
            </motion.h1>
        );
    };
    
  export  const AnimatedText = ({ children, className, variants = textVariants }) => {
      const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.05
      });
    
      return (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={variants}
          className={className}
        >
          {children}
        </motion.div>
      );
    };
    

  
 export const AnimatedSection = ({ children, className = "" }) => {
  const [ref, inView] = useInView({ 
    triggerOnce: false,
    threshold: 0.05,
    rootMargin: "-50px"
  });
  
  const fadeUpVariants = {
    hidden: { 
      opacity: 0,
      y: 50, // Reduced from 100 to minimize movement
      transition: {
        type: "tween", // Using tween instead of spring for smoother motion
        ease: "easeOut",
        duration: 0.5
      }
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeOut",
        duration: 0.5
      }
    }
  };

  return (
    <div className="transform-gpu"> {/* Force GPU acceleration */}
      <motion.div
        ref={ref}
        variants={fadeUpVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className={`mx-[3vw] my-16 will-change-transform`} // Added will-change-transform
        style={{
          backfaceVisibility: "hidden", // Prevent flickering
          perspective: 1000,
          WebkitFontSmoothing: "antialiased"
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};
export  const FullScreenParallax = ({ image, height = "100vh", children, strength = 200 }) => (
  <Parallax
    bgImage={image}
    bgImageStyle={{
        objectFit: "cover",
        height:"100%",
        width:"100%"

      }}
    strength={strength}
    className="relative "
    renderLayer={percentage => (
      <div
        style={{
          position: "absolute",
          background: `rgba(0, 0, 0, ${0.5 - percentage * 0.2})`,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%"
        }}
      />
    )}
  >
    <div style={{ height }} className="relative flex items-center justify-center">
      {children}
    </div>
  </Parallax>
);

  export  const ParallaxSection = ({ src, height = "80vh", className = "", children }) => (
    <Parallax
      bgImage={src}
      bgImageStyle={{
        objectFit: "cover",

      }}
      strength={300}
      className={`mb-8  ${className}`}
      renderLayer={percentage => (
        <div
          style={{
            position: "absolute",
            background: `rgba(0, 0, 0, ${0.3 - percentage * 0.3})`,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit:"cover"
          }}
        />
      )}
    >
      <div  className={`${height}`}>
        {children}
      </div>
    </Parallax>
  );

 export const HeroSection = ({ variety, decodedVariety, children }) => {
    return (
      <div className="relative">
        <ParallaxSection
          src={require(`../../assets/images/stories/Pictures/${variety}.webp`)}
          height="md:h-[100vh] h-[60vh]"
          className="relative"
        >
          <motion.div 
            className="absolute inset-0 bg-[#00000058] z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
          
          <motion.div 
            className="relative z-20 h-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <AnimatedTitle text={decodedVariety} />
            </div>
            {children}
          </motion.div>
        </ParallaxSection>
      </div>
    );
  };

 export  const AnimatedComparisonTable = ({ data }) => {
    const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.05 });
  
    const tableVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1
        }
      }
    };
  
    const rowVariants = {
      hidden: { 
        opacity: 0,
        y: 20
      },
      visible: { 
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          ease: "easeOut"
        }
      }
    };
  
    return (
      <div className="overflow-auto scrollbar-hide" style={{ 
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
        <motion.table 
          ref={ref}
          className="w-full border-collapse"
          variants={tableVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          <thead>
            <motion.tr 
              className="font-medium text-left"
              variants={rowVariants}
            >
              <th className="border-2 md:p-4 p-2 border-black border-solid">Aspect</th>
              <th className="border-2 md:p-4 p-2 border-black border-solid">Veneer</th>
              <th className="border-2 md:p-4 p-2 border-black border-solid">Laminate</th>
            </motion.tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <motion.tr 
                key={index}
                variants={rowVariants}
              >
                <td className="border-2 md:p-4 p-2 border-black border-solid">{row.Aspect}</td>
                <td className="border-2 md:p-4 p-2 border-black border-solid">{row.Veneer}</td>
                <td className="border-2 md:p-4 p-2 border-black border-solid">{row.Laminate}</td>
              </motion.tr>
            ))}
          </tbody>
        </motion.table>
      </div>
    );
  };
  