import React, { useState, useEffect, useMemo } from 'react';
import MarqueeDiv from './MarqueeDiv';
import Gallery from './Gallery';
import PdfEnquiry from './PdfEnquiry';
import WallComponentLink from './variety/WallComponentLink';
import ProductsLink from './variety/ProductsLink';
import { Footer } from '../Footer';
import { Link, useParams } from 'react-router-dom';
import { varietyImages, laminatesimages, veneersimages, woodenimages, varietyMarqueeImages } from '../../../src/utils/images';
import VideoLoader from '../about/VideoLoader';
import { Helmet } from 'react-helmet';

export default function ProductsMain() {
    const { category = 'Plywood' } = useParams();
    let decodedString;
    if (category.includes('&')) {
        decodedString = category.replace(/&/g, ' & ');
    } else {
        decodedString = category.replace(/([A-Z])/g, ' $1').trim();
    }
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [selectedVariety, setSelectedVariety] = useState(decodedString);
    const [selectedVarietyIndex, setSelectedVarietyIndex] = useState(2);
    const [selectedVarietyIndex2, setSelectedVarietyIndex2] = useState(decodedString);

    const [fade, setFade] = useState(false);
    const [loadingImages, setLoadingImages] = useState(true);

    useEffect(() => {
        setLoadingImages(true);
    
        const images = varietyImages[selectedVariety] || [];
    
        if (images.length > 0) {
            let loadedCount = 0;
    
            const checkAllLoaded = () => {
                loadedCount++;
                if (loadedCount === images.length) {
                    setLoadingImages(false);
                }
            };
    
            const promises = images.map(({src, alt}) => {
                return new Promise((resolve) => {
                    if (!src) {
                        console.error(`Invalid image source for ${selectedVariety}`);
                        checkAllLoaded();
                        resolve();
                        return;
                    }
                    const img = new Image();
                    img.onload = () => {
                        checkAllLoaded();
                        resolve();
                    };
                    img.onerror = () => {
                        console.error(`Failed to load image: ${src}`);
                        checkAllLoaded();
                        resolve();
                    };
                    img.src = src;
                });
            });
    
            Promise.all(promises)
                .then(() => {
                    setLoadingImages(false);
                })
                .catch(error => {
                    console.error('Error in image loading process:', error);
                    setLoadingImages(false);
                });
        } else {
            setLoadingImages(false);
        }
    
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % Math.max(images.length, 1));
        }, 2000);
    
        return () => {
            clearInterval(interval);
        };
    }, [selectedVariety]);


    const handleVarietyClick = (index, variety) => {
        if (!varietyImages[variety]) {
            console.error(`No images found for variety: ${variety}`);
            return;
        }
        setLoadingImages(true);
        setSelectedVarietyIndex(index);
        setSelectedVarietyIndex2(variety);
        setFade(true);
        setTimeout(() => {
            setSelectedVariety(variety);
            setCurrentImageIndex(0);  
            setFade(false);
            setLoadingImages(false);
        }, 500);
    };

    const handleNextVariety = () => {
        const nextIndex = (selectedVarietyIndex + 1) % 8;
        const nextVariety = ['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][nextIndex];
        handleVarietyClick(nextIndex, nextVariety);
        setSelectedVariety(nextVariety)
        console.log(nextVariety)
    };

    const handlePrevVariety = () => {
        const prevIndex = (selectedVarietyIndex + 7) % 8;
        const prevVariety = ['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][prevIndex];
        handleVarietyClick(prevIndex, prevVariety);
        setSelectedVariety(prevVariety)
        console.log(prevVariety)
    }

    return (
        <div>
            <div className={`relative bg-white`}>
                <div className='h-[45vh] '>
                    <img className='absolute z-[1] h-[45vh] max-md:hidden w-screen object-cover transition-opacity duration-500' src={require("../../assets/images/products/Rectangle.png")} alt="" />
                    <img
                        className={`h-[45vh] w-screen object-cover transition-opacity duration-500`}
                        src={varietyImages[selectedVariety]?.[currentImageIndex]?.src || ''}
                        alt={varietyImages[selectedVariety]?.[currentImageIndex]?.alt || ''}
                    />
                    {loadingImages &&
                        <div className='h-[45vh] absolute top-0 left-0 bg-[#0000004e] backdrop-blur-[5px]  right-0 bottom-0 flex justify-center items-center'>
                            <VideoLoader />
                        </div>
                    }

                </div>
                <div className="absolute z-[2] max-md:hidden  shadow-current bottom-0 left-0 right-0 transform -translate-y-1/2 flex justify-center gap-[1.5vw] ">
                    {['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'].map((variety, index) => (
                        <Link key={index} to={`/products/${variety.replace(/ /g, '')}`}>
                            <p
                                key={index}
                                className={`relative h-[6vh] text-white transition-all font-thin duration-[600ms] cursor-pointer flex items-center justify-center  
                                ${selectedVarietyIndex2 === variety ? "text-[2.8vw] py-[1vw] [font-family:'PP_Hatton-medium',Helvetica]" : "font-['Poppins'] text-[1vw]"} 
                               `}
                                onClick={() => handleVarietyClick(index, variety)}
                            >
                                {variety}
                            </p>
                        </Link>
                    ))}

                </div>
                <div className="absolute z-[2] md:hidden h-full  bg-[#00000057] bottom-0 left-0 right-0 transform ">
                    <div className="flex justify-center items-center gap-5">
                        {['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'].map((variety, index) => (
                            <p
                                key={index}
                                className={`absolute  text-white transition-all top-1/2 font-thin items-end cursor-pointer duration-100 flex    ${fade ? ' scale-0' : ' scale-100'} ${selectedVariety === variety ? "text-[7.7vw] py-2 [font-family:'PP_Hatton-medium',Helvetica] " : "hidden"}`}
                                onClick={() => handleVarietyClick(index, variety)}
                            >
                                {variety}
                            </p>

                        ))}
                    </div>

                    <div className={`absolute z-[2]  bottom-12 left-0 ${fade ? ' scale-75 opacity-0' : ' scale-100 opacity-100'} right-0 flex justify-between mx-8     md:hidden text-white text-sm transition-all duration-[600ms]`}>
                        <p>{['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][(selectedVarietyIndex + 7) % 8]}</p>
                        <p>{['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][(selectedVarietyIndex + 1) % 8]}</p>
                    </div>

                    <div>
                        <div className="absolute z-[2]  bottom-5 left-0 right-0 flex justify-between mx-4 md:hidden transition-all duration-[600ms]">
                            <Link to={`/products/${['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][(selectedVarietyIndex + 7) % 8].replace(/ /g, '')}`}>
                                <button onClick={handlePrevVariety} className="text-white">
                                    <img src={require("../../assets/images/back.png")} className='filter invert' alt="" />
                                </button>
                            </Link>
                            <Link to={`/products/${['Plywood', 'Veneers', 'Laminates', 'Wooden Flooring', 'Wall Panels', 'Modular Interiors', 'Exterior Materials', 'Adhesives'][(selectedVarietyIndex + 1) % 8].replace(/ /g, '')}`}>
                                <button onClick={handleNextVariety} className="text-white">
                                    <img className='filter invert' src={require("../../assets/images/forward.png")} alt="" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>


            </div>
            <div className={` mx-[3vw] transition-opacity duration-[900ms] ${fade ? 'opacity-0' : 'opacity-100'}`} >
                {selectedVariety === 'Plywood' && (
                    <>

                        <Helmet>
                            <title>Plywood - Vikas Plywood</title>
                            <meta property="og:keywords" content="Plywood, Gurjan, Birch plywood, BWP, IS 710, Hardwood, MR grade, Greenply, VIN ply, Dexo, Mikasa ply, Plywood board, Plywood sheet, Waterproof plywood" />
                            <meta name="keywords" content="Plywood, Gurjan, Birch plywood, BWP, IS 710, Hardwood, MR grade, Greenply, VIN ply, Dexo, Mikasa ply, Plywood board, Plywood sheet, Waterproof plywood" />
                            <meta name="description" content=" Plywood is the backbone of a multitude of design structures. Plywood, also known as the superhero of boards, is a composite marvel, crafted from three or more thin layers of genuine wood, bonded together to conquer any project. From being a backbone to your walls and ceilings, to sculpting sleek furniture, plywood does it all.
                        The standard size of a plywood sheet is 8 feet in height and 4 feet in width (8x4) and is available in five variations of thickness - 6mm, 8mm, 12mm, 16mm, and 18mm. 
                        Step into Vikas Plywood, to discover plywood in all its forms from the leading names in the industry. Let us help you determine the perfect match to fit your needs.." />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Plywood is the backbone of a multitude of design structures. Plywood, also known as the superhero of boards, is a composite marvel, crafted from three or more thin layers of genuine wood, bonded together to conquer any project. From being a backbone to your walls and ceilings, to sculpting sleek furniture, plywood does it all.
                            The standard size of a plywood sheet is 8 feet in height and 4 feet in width (8x4) and is available in five variations of thickness - 6mm, 8mm, 12mm, 16mm, and 18mm. <br /> <br />
                            Step into Vikas Plywood, to discover plywood in all its forms from the leading names in the industry. Let us help you determine the perfect match to fit your needs.
                        </div>
                    </>
                )}
                {selectedVariety === 'Veneers' && (
                    <>
                        <Helmet>
                            <title>Veneers - Vikas Plywood</title>
                            <meta property="og:keywords" content="Veneers, VIN veneers, KD veneers, Woodcrest, Decowood, Veneer door, Veneer door design, Veneer on doors, Veneer sheets, Veneer texture, Veneer near me, Veneer meaning, Veneer polish, Pre-polished veneers, Natural veneers, Paper veneer, Unpolished veneers" />
                            <meta name="keywords" content="Veneers, VIN veneers, KD veneers, Woodcrest, Decowood, Veneer door, Veneer door design, Veneer on doors, Veneer sheets, Veneer texture, Veneer near me, Veneer meaning, Veneer polish, Pre-polished veneers, Natural veneers, Paper veneer, Unpolished veneers" />
                            <meta name="description" content="A veneer is the embodiment of the exquisite charm inherent in natural wood—meticulously chosen and crafted to infuse the warmth and character of nature into your interiors. With rich grains and distinctive designs, our natural veneers add a touch of sophistication, transforming everyday surfaces into extraordinary statements." />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            A veneer is the embodiment of the exquisite charm inherent in natural wood—meticulously chosen and crafted to infuse the warmth and character of nature into your interiors. With rich grains and distinctive designs, our natural veneers add a touch of sophistication, transforming everyday surfaces into extraordinary statements.
                            <br /><br /> Discover the beauty of nature’s craftsmanship with our curated collection of natural veneers.

                        </div>
                    </>
                )}
                {selectedVariety === 'Laminates' && (
                    <>
                        <Helmet>
                            <title>Laminates - Vikas Plywood</title>
                            <meta property="og:keywords" content="Laminates, Laminates for kitchen, Laminates for wardrobe, Laminates near me, Laminates door, laminates for plywood, Laminates greenlam, Laminates royal touch, Merinolam, Laminates sheet, Decolam" />
                            <meta name="keywords" content="Laminates, Laminates for kitchen, Laminates for wardrobe, Laminates near me, Laminates door, laminates for plywood, Laminates greenlam, Laminates royal touch, Merinolam, Laminates sheet, Decolam" />
                            <meta name="description" content="Laminates are durable, versatile materials used in interior design to breathe life into surfaces like furniture, cabinetry, doors, and countertops. Unlike veneers, which are thin layers of real wood, laminates consist of paper or fabric soaked in resin, topped with a protective layer." />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Laminates are durable, versatile materials used in interior design to breathe life into surfaces like furniture, cabinetry, doors, and countertops. Unlike veneers, which are thin layers of real wood, laminates consist of paper or fabric soaked in resin, topped with a protective layer.
                            With an array of  designs and low maintenance charm, laminates are a popular design choice.
                            Vikas Plywood is the distributor for most of the leading manufacturers in the laminate industry such as Greenlam, Merinolam, Centurylam, Royal Touche, and other leading laminate manufacturers from India. <br />
                            <br /> Explore our collection to find the perfect fit for your project!
                        </div></>
                )}
                {selectedVariety === 'Wooden Flooring' && (
                    <>
                        <Helmet>
                            <title>Wooden Flooring - Vikas Plywood</title>
                            <meta property="og:keywords" content="Flooring, Wooden flooring, VIN flooring, Engineered wood, Wooden tiles, Wooden flooring tiles, Wooden flooring design, Wooden flooring for bedroom, Laminate wooden flooring, Wooden flooring texture, Laminate flooring, Mikasa, Kronotex, Xylos, Designer flooring" />
                            <meta name="keywords" content="Flooring, Wooden flooring, VIN flooring, Engineered wood, Wooden tiles, Wooden flooring tiles, Wooden flooring design, Wooden flooring for bedroom, Laminate wooden flooring, Wooden flooring texture, Laminate flooring, Mikasa, Kronotex, Xylos, Designer flooring" />
                            <meta name="description" content="Crafted from nature’s design, wooden floorings add a natural charm to any space. Your floor forms the foundation of your space and wooden flooring is a great alternative to traditional flooring for its durability, ease on the knees and natural aesthetic. With a diverse range of textures and shades to choose from, our wooden flooring will add character to your residential and commercial spaces." />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Crafted from nature’s design, wooden floorings add a natural charm to any space. Your floor forms the foundation of your space and wooden flooring is a great alternative to traditional flooring for its durability, ease on the knees and natural aesthetic. With a diverse range of textures and shades to choose from, our wooden flooring will add character to your residential and commercial spaces.
                            <br /><br />  At Vikas Plywood, we offer two exceptional types of wooden flooring to bring nature’s beauty home in any form that suits your needs.
                        </div></>
                )}
                {selectedVariety === 'Wall Panels' && (
                    <>

                        <Helmet>
                            <title>Wall Panels - Vikas Plywood</title>
                            <meta property="og:keywords" content="AGT, Wall panels, European profiles, Wall profiles, Decorative wall panels, Claddings, PVC wall panels, PVC panels, Wall cladding, Louvers, Wall panels bedroom, Wall panels for living room, Wall panels decorative, Louvre panel, Wall wooden panelling, Wooden wall panels, Wall Cladding Exterior, PVC profiles, Decorative panels, Solid wood profiles, VIN profiles" />
                            <meta name="keywords" content="AGT, Wall panels, European profiles, Wall profiles, Decorative wall panels, Claddings, PVC wall panels, PVC panels, Wall cladding, Louvers, Wall panels bedroom, Wall panels for living room, Wall panels decorative, Louvre panel, Wall wooden panelling, Wooden wall panels, Wall Cladding Exterior, PVC profiles, Decorative panels, Solid wood profiles, VIN profiles" />
                            <meta name="description" content="Wall panels, or wall profiles, are decorative elements that enhance both the aesthetics and functionality of interior spaces. Crafted from materials such as wood, metal, or a composite, these panels elevate the visual aspect of walls while simultaneously offering soundproofing features. Ideal for a range of settings, including residential, commercial, and industrial spaces, wall panels effortlessly transform ordinary walls into visually striking surfaces.
" />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Wall panels, or wall profiles, are decorative elements that enhance both the aesthetics and functionality of interior spaces. Crafted from materials such as wood, metal, or a composite, these panels elevate the visual aspect of walls while simultaneously offering soundproofing features. Ideal for a range of settings, including residential, commercial, and industrial spaces, wall panels effortlessly transform ordinary walls into visually striking surfaces.
                            <br /><br />
                            Explore our collection of wooden wall panels to redefine the ambience of your surroundings, creating a space that is both captivating and purposeful.

                        </div> </>
                )}
                {selectedVariety === 'Modular Interiors' && (
                    <>
                        <Helmet>
                            <title>Modular Interiors - Vikas Plywood</title>
                            <meta property="og:keywords" content="Kitchens, Wardrobes, Modular Kitchen and Wardrobe, Kitchen Cabinets, Kitchen Cabinets and Colours, Kitchen and Wardrobe Design, Kitchen Cabinets and Design, Kitchen Cabinets Modern, Kitchen Cabinets Wood, Wardrobe Bedroom Design, Wardrobe Design for Bedroom, Wardrobe Colour, Wardrobe Closet, Wardrobe Colour Combination, Wardrobe Door Design, Wardrobe Design with Mirror, Wardrobe Design Latest, Wardrobes for Bedrooms, Wardrobe Laminate Design, Wardrobes with Sliding Doors, Wardrobes with Dressing Table, Vittaazio" />
                            <meta name="keywords" content="Kitchens, Wardrobes, Modular Kitchen and Wardrobe, Kitchen Cabinets, Kitchen Cabinets and Colours, Kitchen and Wardrobe Design, Kitchen Cabinets and Design, Kitchen Cabinets Modern, Kitchen Cabinets Wood, Wardrobe Bedroom Design, Wardrobe Design for Bedroom, Wardrobe Colour, Wardrobe Closet, Wardrobe Colour Combination, Wardrobe Door Design, Wardrobe Design with Mirror, Wardrobe Design Latest, Wardrobes for Bedrooms, Wardrobe Laminate Design, Wardrobes with Sliding Doors, Wardrobes with Dressing Table, Vittaazio" />
                            <meta name="description" content=" Vikas Plywood has expanded its product portfolio to include luxurious modular interiors, encompassing kitchen, wardrobe, and vanity furniture, making us your ultimate destination for all interior needs. Our company offers bespoke, multi-faceted designs with a promise of durability and impeccable finishing making it perfect for modern living spaces." />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Vikas Plywood has expanded its product portfolio to include luxurious modular interiors, encompassing kitchen, wardrobe, and vanity furniture, making us your ultimate destination for all interior needs. Our company offers bespoke, multi-faceted designs with a promise of durability and impeccable finishing making it perfect for modern living spaces.
                            <br /><br />
                            Explore our comprehensive range today and take your space to new heights of elegance and functionality.

                        </div>
                    </>
                )}
                {selectedVariety === 'Exterior Materials' && (
                    <>
                        <Helmet>
                            <title>Exterior Materials - Vikas Plywood</title>
                            <meta property="og:keywords" content="Deck Flooring, Exterior Cladding, Outdoor Flooring, Outdoor Cladding, IPE Deck Flooring, WPC Deck Flooring, IPE Flooring, WPC Flooring, IPE Decking, WPC Cladding, WPC Rafters, Java Teak, Best Exterior Cladding, Best Exterior Flooring, WPC Exterior Wall Panels" />
                            <meta name="keywords" content="Deck Flooring, Exterior Cladding, Outdoor Flooring, Outdoor Cladding, IPE Deck Flooring, WPC Deck Flooring, IPE Flooring, WPC Flooring, IPE Decking, WPC Cladding, WPC Rafters, Java Teak, Best Exterior Cladding, Best Exterior Flooring, WPC Exterior Wall Panels" />
                            <meta name="description" content="  Vikas Plywood believes in bringing natural beauty to your home. Wood looks best in the outdoors, that’s why we have curated a selection of exterior wall cladding and deck flooring that have brought smiles to numerous clients across India.
" />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Vikas Plywood believes in bringing natural beauty to your home. Wood looks best in the outdoors, that’s why we have curated a selection of exterior wall cladding and deck flooring that have brought smiles to numerous clients across India.
                            <br /><br /> Explore our range of exterior wood-based cladding and deck flooring to transform your exteriors into a story of enduring elegance. Discover the perfect combination of protection and style for your outdoor spaces with our premium selections
                        </div>
                    </>
                )}
                {selectedVariety === 'Adhesives' && (
                    <>
                        <Helmet>
                            <title>Adhesives - Vikas Plywood</title>
                            <meta property="og:keywords" content="Adhesives, Fevicol for Veneer, VIN Grip, Super Glue for Veneer, Advanced Adhesives, Spray Adhesive for Veneer and Laminates, VIN glue" />
                            <meta name="keywords" content="Adhesives, Fevicol for Veneer, VIN Grip, Super Glue for Veneer, Advanced Adhesives, Spray Adhesive for Veneer and Laminates, VIN glue" />
                            <meta name="description" content="Adhesives refer to substances that when applied between the surfaces of two or more materials or objects (substrates), can be used to hold, fasten, or bond them together. Adhesives are widely employed throughout the industry for permanent and semi-permanent attachment purposes in a variety of residential, commercial, and industrial applications.
" />
                        </Helmet>
                        <div className="md:max-w-[90%]   md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                            Adhesives refer to substances that when applied between the surfaces of two or more materials or objects (substrates), can be used to hold, fasten, or bond them together. Adhesives are widely employed throughout the industry for permanent and semi-permanent attachment purposes in a variety of residential, commercial, and industrial applications.
                            <br /><br />
                            Our collection of advanced adhesives consists of three standout products tailored for diverse needs - VIN Grip 107 Aerosol, VIN Cleaner C-101, and VIN Grip 153 Aerosol.

                        </div>
                    </>
                )}
                <div className='my-10'>
                    <h1 className="[font-family:'PP_Hatton-medium',Helvetica] font-medium text-[5vw] md:text-[4vh] text-black">
                        Our Brand Partners
                    </h1>
                    <div className={`flex  justify-center  transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}>
                        <MarqueeDiv images={varietyMarqueeImages[selectedVariety]} />
                    </div>
                </div>
                {selectedVariety !== 'Laminates' && (
                    <h1 className="[font-family:'PP_Hatton-medium',Helvetica] font-medium text-[4vh] text-black">
                        Collections
                    </h1>
                )}

                <div className={`grid grid-cols-1  md:grid-cols-2 gap-[2vw] mb-[2vw] ${fade ? 'fade-out' : 'fade-in'}`}>
                    {selectedVariety === 'Plywood' && (
                        <>
                            <ProductsLink alt="Okoume trees from where the hardwood is made" link="plywood" variety="Hardwood (MR-grade)" path="products/plywood/1.png" />
                            <ProductsLink alt="Eucalyptus trees from where the boiling waterproof plywood is made" link="plywood" variety="Boiling Water Proof (BWP)" path="products/plywood/2.png" />
                            <ProductsLink alt="Gurjan trees from where the gurjan plywood is made" link="plywood" variety="Gurjan Plywood" path="products/plywood/2.png" />
                            <ProductsLink alt="Birch trees from where the birch plywood is made" link="plywood" variety="Birch Plywood" path="products/plywood/1.png" />
                        </>
                    )}
                    {selectedVariety === 'Veneers' && (
                        <>
                            <ProductsLink alt="Thumbnail Natural Veneers" link="veneer" variety="Natural Veneers" path="products/veneer/natural_veneers.jpg" />
                            <ProductsLink alt="Thumbnail Pre-polished Veneers" link="veneer" variety="Pre-Polished Veneers" path="products/veneer/pre-polished_veneers.jpg" />
                        </>
                    )}
                    {selectedVariety === 'Wooden Flooring' && (
                        <>
                            <ProductsLink alt="Engineered Wooden Flooring" arrow="white" link="wooden_flooring" variety="Engineered Wooden Flooring" path="products/wooden/1.png" />
                            <ProductsLink alt="Laminated Wooden flooring by Kronote" arrow="white" link="wooden_flooring" variety="Laminated Wooden Flooring" path="products/wooden/1.png" />
                        </>
                    )}
                    {selectedVariety === 'Wall Panels' && (
                        <>
                            <WallComponentLink alt="A bedroom with teak coloured AGT wall panels" link="wall" imagestyle="h-[65vh]" variety="AGT Wall Panels" path="wall/1.png" image="wall" />
                            <WallComponentLink alt="A TV unit decorated with solid wood panels from VIN" link="wall" imagestyle="h-[65vh]" arrow="white" variety="Solid Wood Panels" path="wall/1.png" image="wall" />
                        </>
                    )}
                    {selectedVariety === 'Modular Interiors' && (
                        < >
                            <WallComponentLink alt="U shaped kitchen with Acrylic finish" link="kitchen" imagestyle="h-[65vh]" variety="Kitchens" path="wall/1.png" image="wall" />
                            <WallComponentLink alt="European style openable wardrobe with PU finish" link="kitchen" imagestyle="h-[65vh]" variety="Wardrobes" path="wall/1.png" image="wall" />
                        </>
                    )}
                    {selectedVariety === 'Exterior Materials' && (
                        <>
                            <ProductsLink alt="IPE deck flooring" link="exterior" variety="Deck Flooring" path="products/exterior/1.png" />
                            <ProductsLink alt="Java Teak used as exterior cladding" link="exterior" variety="Exterior Cladding" path="products/exterior/2.png" />
                        </>
                    )}
                    {selectedVariety === 'Adhesives' && (
                        <>
                            <ProductsLink alt="VIN grip 107 advanced adhesive" link="adhesives" variety="VIN Grip 107 Aerosol" path="project/image1.png" />
                            <ProductsLink alt="VIN Cleaner C-101 advanced cleaner" link="adhesives" variety="VIN Cleaner C-101" path="project/image1.png" />
                            <ProductsLink alt="VIN 153 advanced adhesive" link="adhesives" variety="VIN Grip 153 Aerosol" path="project/image1.png" />
                        </>
                    )}
                </div>

            </div>
            <div>
                {selectedVariety === 'Laminates' && (
                    <div className=''>
                        <div className='ml-[5vw]'>
                            <Gallery images={laminatesimages} />
                        </div>
                        <div className='grid mx-[4vw] border-2 place-self-center mt-[2vw] border-red-500 grid-cols-1 sm:grid-cols-2 '>
                            <PdfEnquiry category={"laminates"} link="1P7crjAaxEmvzD-7x60Pp6DZopZa7w_7V" name="Greenlam Laminates" variety="Laminates" />
                            <PdfEnquiry category={"laminates"} link="1pmXxsN54ru-dImGqnAkRtg1n8JXSruIm" name="Merino Laminates" variety="Laminates" />
                            <PdfEnquiry category={"laminates"} link="19KgbtOu9aKb_G35dMsUftgVn3HkocLhY" name="Royale Touche" variety="Laminates" />
                            <PdfEnquiry category={"laminates"} link="1pkRRLe4lWmp8q_7_mmyEF1vQ2o-YW8xL" name="Century Laminates" variety="Laminates" />
                        </div>
                    </div>
                )}
            </div>
            <div className='ml-[5vw]'>
                {selectedVariety === 'Veneers' && (
                    <Gallery images={veneersimages} />
                )}
            </div> 
            <div className='ml-[5vw]'>
                {selectedVariety === 'Wooden Flooring' && (
                    <Gallery images={woodenimages} />
                )}
            </div>
            <Footer />
        </div>
    );
}
