import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Footer } from '../../Footer';
import EnquiryButton from '../../EnquiryButton';

export default function AdhesivesComponent() {
  const { variety } = useParams();
  // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
    const decodedVariety = variety .split("_")  .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  const [zoomIn, setZoomIn] = useState(false);
  const [currentImage, setCurrentImage] = useState(1);
  const [linePosition, setLinePosition] = useState('left');
  const handleClickImage = () => {
    setCurrentImage(currentImage === 3 ? 1 : currentImage + 1);
    setLinePosition(linePosition === 'left' ? 'right' : 'left');
  };
  useEffect(() => {
    setZoomIn(true);
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
    window.addEventListener('popstate', handleRouteChange);

    return unlisten;
  }, []);

  const [transformOrigin, setTransformOrigin] = useState(null);
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setTransformOrigin(`${x}px ${y}px`);
  };

  return (
    <div>
      <motion.div
      >
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>
        <div className={`new-component bg-[#6d744c] md:h-[85vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
          <h1 className="text-[#F7F3EA] whitespace-nowrap absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
            {decodedVariety}
          </h1>
          <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
            <Link className='invert' to={`/products/Adhesives`} >
              <img src={require("../../../assets/images/project/arrow.png")} alt="" />
            </Link>
          </div>
          <div className='flex absolute text-[#F7F3EA] bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
            <Link to="/">
              Home
            </Link>
            /
            <Link to="/products/Adhesives">
              Products
            </Link>
            /
            <p >
              Adhesives
            </p>
            /
            <p   className='max-md:hidden'>
              {decodedVariety}
            </p>
            <p  className='md:hidden' >
              {decodedVariety.split(' ')[2]}
            </p>
          </div>
        </div>
        <div className="relative w-full pb-[5vh]   bg-[#F7F3EA] text-black ">
          <div className='mx-[3vw] flex justify-center md:justify-between gap-[2vw] ' >
            <div className='flex md:flex-row flex-col  md:gap-[2vw] items-center justify-center md:justify-between'>
              <div className="border cursor-pointer flex justify-center  border-black text-[3vh] font-medium font-['Poppins'] mt-5 md:max-md:my-10 mt-10">
              <div className="relative overflow-hidden">
                <div className="relative">
                  <img
                    className="w-[60vh] max-md:object-cover h-[40vh] cursor-pointer lg:h-[65vh]"
                    src={require(`../../../assets/images/products/adhesives/${variety}/${currentImage}.jpg`)}
                    alt=""
                    onClick={handleClickImage}
                  />
                  <div
                    className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out"
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseLeave={() => setTransformOrigin(null)}
                  >
                    <div onClick={handleClickImage} className="w-full h-full overflow-hidden">
                      <img
                        className="w-full h-full object-cover transform lg:hover:scale-150   transition-transform duration-300 ease-in-out"
                        src={require(`../../../assets/images/products/adhesives/${variety}/${currentImage}.jpg`)}
                        alt=""
                        style={{ transformOrigin: transformOrigin }}
                      />
                    </div>
                  </div>
                </div>
                <div onClick={handleClickImage} className="w-[7vw] h-[5px] cursor-pointer mt-[1vw] mx-auto relative">
                  <div
                    className={`absolute bottom-0 ${linePosition === 'left' ? 'left-0' : 'right-0'} w-1/4 border-b-[1px] border-solid border-black`}
                  />
                  <div
                    className={`absolute bottom-0 ${linePosition === 'left' ? 'right-0' : 'left-0'} w-2/4 border-b-[2px] border-solid border-black`}
                  />
                </div>
              </div>

              </div>
              <div className='flex md:mx-[2vw] md:w-[60vw] mt-5 md:max-md:my-10 mt-10  gap-[1vw]'>
                <div className=''>
                  <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  font-medium mb-[1vw]  text-[4vh] text-black " > {decodedVariety}</h1>
                  {variety === "vin_grip_107_aerosol" && (
                    <div>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">
                        VIN Grip 107 Aerosol is a super-strength general-purpose contact adhesive designed for a variety of standard applications.
                        107 bonds melamine without the need for surface preparation and is also ideal for bonding carpet to staircases. This also includes post-forming, bonding laminate, sheet metal, leather, fibre, glass, and certain plastics.

                      </p>
                      <p className="font-['Poppins'] mb-[1vw]  font-thin md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Suitable Substrates</span> - Laminates, plastics, sheet metal, wood, melamine, fibre and glass-reinforced plastics and laminates, post-forming, PVC edging, carpet, and more. <br />
                      </p>
                      <p className="font-['Poppins'] mb-[1vw]  font-thin  md:text-[1.7vw] max-md:my-2">
                        <span className='font-medium'>Advantages</span> - High initial strength, fast tack, high-temperature resistance, low odour, low VOC, toluene-free.<br />
                      </p>
                      <p className="font-['Poppins']  mb-[1vw]  font-thin md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Available in </span>- 500ml and 22L <br />
                      </p>
                    </div>
                  )}

                  {variety === "vin_cleaner_c-101" && (
                    <div>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">
                        Vin Cleaner is a biodegradable, citrus solvent cleaner, degreaser, and adhesive remover. It can be safely used on most surfaces and substrates, including painted surfaces, glass, porcelain, and concrete, and is compatible with many plastics and vinyl.
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Removes</span> - Adhesive, tar, oil, stickers, paint, ink, and grease.<br />
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">
                        <span className='font-medium'>Advantages</span> - Biodegradable, real citrus, breaks down adhesives, no oily residue.<br />
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Available in </span>- 500ml <br />
                      </p>
                    </div>
                  )}
                  {variety === "vin_grip_153_aerosol" && (
                    <div>
                      <p className="[font-family:'Poppins'] mb-[1vw]  font-thin md:text-[1.7vw] max-md:my-2">
                      VIN grip 153 is an all-round contact adhesive that performs many tasks. It is specially formulated to be very low in VOCs and to be compatible with most rubber substrates. 
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Suitable Substrates</span> - Rubber, Foam, laminates, leather, fabric, EVA foam, fibre and glass reinforced plastics, plastics, acrylic, insulation, MDF, carpet, and more. <br />
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">
                        <span className='font-medium'>Advantages</span> - Lacquer resistant, fast tack, high-temperature resistance, low odour, low VOC, toluene-free.<br />
                      </p>
                      <p className="font-['Poppins'] font-thin mb-[1vw]   md:text-[1.7vw] max-md:my-2">

                        <span className='font-medium'>Available in </span>- 500ml <br />
                      </p>
                    </div>
                  )}

                  <EnquiryButton name={decodedVariety} variety={"Adhesives"} />
                </div>

              </div>
            </div>
          </div>
        </div>

      </motion.div>
      <Footer />
    </div>
  );
}
