import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, Link } from 'react-router-dom';
// import WallComponentLink from './WallComponentLink';
import Gallery from '../Gallery';
import WallComponentLink from './WallComponentLink';
import { Footer } from '../../Footer';
import KitchenLink from './KitchenLink';
export const kitchenimages = [
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/1.jpg"),
      alt: "Peninsula kitchen design"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/2.jpg"),
      alt: "Straight kitchen design by VIN"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/3.jpg"),
      alt: "L shaped kitchen with a mix of laminate and veneer finishes"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/4.jpg"),
      alt: "Straight kitchen with laminate finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/5.jpg"),
      alt: "Parallel kitchen design"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/6.jpg"),
      alt: "Island kitchen by VIN"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/7.jpg"),
      alt: "U shaped kitchen"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/8.jpg"),
      alt: "U shaped kitchen with rich green acrylic finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/9.jpg"),
      alt: "Parallel kitchen with an island"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Kitchens/10.jpg"),
      alt: "U shaped kitchen with acrylic finish at Providence Hyderabad"
  }
];

export const wordimages = [
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Wardrobes/1.jpg"),
      alt: "Walk-in wardrobe with a veneer finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Wardrobes/2.jpg"),
      alt: "Openable wardrobe with glass finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Wardrobes/3.jpg"),
      alt: "Openable wardrobe with a laminate finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Wardrobes/4.jpg"),
      alt: "Sleek openable wardrobe with a glass finish"
  },
  { 
      src: require("../../../assets/images/products/kitchen/Gallery_Wardrobes/5.jpg"),
      alt: "Walk-in wardrobe designed by VIN"
  }
];

export default function KitchensComponent() {
  const { variety } = useParams();
  // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
    const decodedVariety = variety .split("_")  .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  const [zoomIn, setZoomIn] = useState(false);
  useEffect(() => {
    setZoomIn(true);
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
    window.addEventListener('popstate', handleRouteChange);

    return unlisten;
  }, []);

  return (
    <div>
      <motion.div
        className='overflow-hidden'
      >
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>
        <div className={`new-component md:h-[85vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
          <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
            {decodedVariety}
          </h1>
          <img
            className="overflow-hidden  object-fit md:h-[85vh] h-[60vh] w-screen"
            src={require(`../../../assets/images/products/kitchen/${variety}.jpg`)}
            alt="selected story"
          />
          <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
            <Link  to={`/products/ModularInteriors`} >
              <img src={require("../../../assets/images/project/arrow.png")} alt="" />
            </Link>
          </div>
          <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
            <Link to="/">
              Home
            </Link>
            /
            <Link to="/products/ModularInteriors">
              Products
            </Link>
            /
            <p >
            Modular Interiors
            </p>
            /
            <p  >
              {decodedVariety}
            </p>
          </div>
        </div>
        {variety === 'kitchens' && (
          <div>
            <div className="relative w-full pb-[5vh] bg-[#F7F3EA] text-black ">
              <div className='mx-[3vw]' >
                <div className="md:max-w-[80%] border border-black md:text-[3vh] font-thin  font-['Poppins'] my-10">
                  Designed to blend style with utility, our modular kitchens offer a seamless cooking experience tailored to your lifestyle. Crafted from top-quality materials, each kitchen is a masterpiece of durability and aesthetics. From sleek modern designs to timeless classics, our modular kitchens cater to every taste and preference. Transform your kitchen into the heart of your home with Vikas Plywood's custom kitchen solutions.
                  <br /> <br />
                  Learn More —
                </div>
                <h1 className="[font-family:'PP_Hatton-medium',Helvetica] mb-[2vw] mt-[4vw]  font-medium  text-[4vh] text-black">
                  Collections
                </h1>
                <div className='grid border-2 border-red-500 gap-[2vw] md:grid-cols-3 grid-cols-1 '>
                  <KitchenLink alt="L shape kitchen by VIN" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Luciole (L-shape)"} path="wall/1.png" />
                  <KitchenLink alt="U shape kitchen by Vikas Plywood" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Benissimo (U-shape)"} path="wall/1.png" />
                  <KitchenLink alt="Straight kitchen by VIN" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Stelle (Straight shape)"} path="wall/1.png" />
                  <KitchenLink alt="Parallel kitchen by Vikas Plywood" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Madeleine (Parallel shape)"} path="wall/1.png" />
                  <KitchenLink alt="Island kitchen by Vikas Plywood" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Essere (Island shape)"} path="wall/1.png" />
                  <KitchenLink alt="Peninsula kitchen by VIN" link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[30vh]" hidden="hidden" variety={"Maestre (Peninsula shape)"} path="wall/1.png" />
                </div>
              </div>
            </div>
            <div className='ml-[3vw]'>
              <Gallery images={kitchenimages} />
            </div>
          </div>
        )}

        {variety === 'wardrobes' && (

          <div>
            <div className="relative w-full pb-[5vh] bg-[#F7F3EA] text-black ">
              <div className='mx-[3vw]' >
                <div className="md:max-w-[80%] border border-black md:text-[3vh] lg:font-thin font-['Poppins'] my-10">
                  Discover the epitome of organisation and elegance with Vikas Plywood's stunning collection of wardrobes. Crafted with precision and attention to detail, our wardrobes are designed to complement your lifestyle while maximising storage space. From spacious walk-in closets to compact yet efficient designs, we offer a wide range of options to suit your needs. Choose from a variety of finishes and styles to create a wardrobe that reflects your personality and enhances your space. Experience the perfect blend of functionality and sophistication with Vikas Plywood's wardrobe solutions.
                  <br /><br />
                  Learn More —
                </div>
                <h1 className="[font-family:'PP_Hatton-medium',Helvetica] mb-[2vw] mt-[4vw]  font-medium  text-[4vh] text-black">
                  Collections
                </h1>
                <div className='grid border-2 border-red-500 gap-[2vw] md:grid-cols-3 grid-cols-1 '>
                <WallComponentLink alt={"Walk in wardrobe by VIN"} link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[40vh]" hidden="hidden" variety={"Allora (Walk-in)"} path="wall/1.png" />
                <WallComponentLink alt={"Sliding wardrobe by VIN"}  link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[40vh]" hidden="hidden" variety={"Comodo (Sliding)"} path="wall/1.png" />
                <WallComponentLink alt={"Openable wardrobe by VIN"}  link="kitchen/KitchenTypes" motionType={'motion1'} imagestyle="h-[40vh]" hidden="hidden" variety={"Monarce (Openable)"} path="wall/1.png" />
                </div>
              </div>
            </div>
            <div className='ml-[3vw]'>
              <Gallery images={wordimages} />
            </div>
          </div>
        )}

        <Footer />
      </motion.div>
    </div>
  );
}
