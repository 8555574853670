import React from 'react';
import { useParams, Link } from "react-router-dom";
import ImageComponent3 from './ImageComponent3';

export default function StoriesInside() {
    const { variety } = useParams(); // Get the current story from URL
    const decodedVariety = variety
    .split("_")
    .map(word => {
        if (word.length === 1 || word.toLowerCase() === "vs") {
            return word;
        }
        const lowercaseWords = ['by', 'with', 'in', 'on', 'at', 'to', 'for', 'of', 'are', 'the', 'future'];
        if (lowercaseWords.includes(word.toLowerCase())) {
            return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");  

      
  return (
    <div>
      <div className='md:mt-[10vh] mt-24 md:h-screen'>
        <div className=' bg-[#f7f3ea] relative overflow-hidden mx-auto my-0'>
        </div>
        <div className="flex my-[2vw] ml-[15px] md:ml-[2vw] items-center ">
          <h1 className="[font-family:'PP_Hatton-medium',Helvetica] text-[13vw] sm:text-[55px] font-medium  text-[#000] relative text-left overflow-hidden z-[25] ">
          Explore More
          </h1>

        </div>
        <div className="flex lg:flex-row mt-[2vw] ml-[2vw] max-md:mr-[2vw] flex-col max-lg:justify-center mx-auto max-lg:items-center overflow-x-scroll custom-scrollbar">
          <ImageComponent3 from="storypage" date={"20 February 2025"} image={`The 8-Step Modular Process at Vikas Plywood`} text={"The 8-Step Modular Process at Vikas Plywood"}  currentStory={decodedVariety} />
        <ImageComponent3 from="storypage" date={"8 November 2024"} image={`VIKAS PLYWOOD The Wooden Wonderland`} text={"VIKAS PLYWOOD: The Wooden Wonderland"} currentStory={decodedVariety} /> 
        <ImageComponent3 from="storypage" date={"22 August 2024"} image={`Why Pre-polished Veneers are the future`} text={"Why Pre-polished Veneers are the future?"}currentStory={decodedVariety} />
        <ImageComponent3 from="storypage" date={"10 June 2024"} image={`AGT Wall Panels`} text={"AGT Wall Panels: Outshining Industry Alternatives with Unrivaled Quality"} currentStory={decodedVariety} />
        <ImageComponent3 from="storypage" date={"12 May 2024"} image={`Veneer vs Laminate`} text={"Veneer vs Laminate: Understanding the Distinctions"} currentStory={decodedVariety} />
        <ImageComponent3 from="storypage" date={"28 April 2024"} image={`Enhancing Your Home`} text={"Enhancing Your Home With Exquisite Laminated Wooden Flooring"} currentStory={decodedVariety} />
      </div>
      </div>
    </div>

  );
}
