import React from 'react';
import { Link } from 'react-router-dom';

export default function ImageComponent3({ image, text, date, from, currentStory }) { // Add currentStory prop
  const shouldRender = from!== "storypage" || image!== currentStory; // Conditional rendering

  if (!shouldRender) {
    return null; 
  }

  console.log(image,"decodedVariety")

  console.log(currentStory,"currentStory")



  return (
    <Link to={from === "storypage"? `/stories/${image.replace(/ /g, '_')}`: `${image.replace(/ /g, '_')}`} rel="noopener noreferrer">
      <div className="lg:hover:scale-105 transition-all duration-500 border-[2px] border-solid lg:w-[40vw] m-[2vw]">
        <img
          className="md:h-[50vh] w-screen h-[300px] flex justify-center mx-auto object-cover"
          src={require(`../../assets/images/stories/Pictures/${image}a.webp`)}
          alt="story"
        />
        <div className="flex justify-between mx-2 md:mx-[1vw] items-center my-2 md:my-[1vw]  [font-family:'PP_Hatton-medium',Helvetica]">
          <h1 className="md:w-[70%] w-[60%] md:text-[1.3vw]">{text}</h1>
          <h1 className="opacity-70 whitespace-nowrap text-[13px] md:text-[1vw]">{date}</h1>
        </div>
      </div>
    </Link>
  );
}