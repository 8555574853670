import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../Navbar/Navbar";
import { useParams, useLocation, Link } from "react-router-dom";
import WallComponentLink from "./WallComponentLink";
import Gallery from "../Gallery";
import FormPopup from "../../../download/FormPopup";
import { Footer } from "../../Footer";
import PdfEnquiry from "../PdfEnquiry";
import WallLInk2 from "./WallLInk2";
export const AGTimages = [
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/8.jpeg"),
      alt: "AGT 3771 Teak displayed in the lobby of Providence"
  },
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/2.jpg"),
      alt: "AGT 2050 Plain white used in the kitchen"
  },
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/3.jpg"),
      alt: "AGT 3771 London Blue in Karachi Bakery Hyderabad"
  },
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/1.jpg"),
      alt: "AGT 3783 installed on the ceiling"
  },
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/5.jpg"),
      alt: "A bedroom with teak coloured AGT wall panels"
  },
  { 
      src: require("../../../assets/images/products/wall/agt_gallery/4.jpg"),
      alt: "AGT 3783 Tobacco used in the lobby"
  }
];


const images = [
  require(`../../../assets/images/products/wall/agt/Background/1.jpg`),
  require(`../../../assets/images/products/wall/agt/Background/2.jpg`),
];
export const solidimages = [
  { 
      src: require("../../../assets/images/products/wall/Gallery/Inspiration gallery/Living_02_grey limba.jpg"),
      alt: "A living room installed with grey limba solid wood panelling"
  },
  { 
      src: require("../../../assets/images/products/wall/Gallery/Inspiration gallery/Living_03_grey poplar.jpg"),
      alt: "A living room installed with walnut solid wood panelling"
  },
  { 
      src: require("../../../assets/images/products/wall/Gallery/Inspiration gallery/TV-Unit_walnut.jpg"),
      alt: "A TV unit decorated with smoked alder natural wood cladding"
  },
  { 
      src: require("../../../assets/images/products/wall/Gallery/Inspiration gallery/solid wood panel_01.jpg"),
      alt: "A bedroom wall installed with bleached teak natural wood cladding"
  }
];

export default function WallComponent() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { image, variety } = useParams();
  // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
  const decodedVariety = variety
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const [zoomIn, setZoomIn] = useState(false);
  useEffect(() => {
    setZoomIn(true);
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
    window.addEventListener("popstate", handleRouteChange);

    return unlisten;
  }, []);
  const [showPopup, setShowPopup] = useState(false);

  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const handleSubmit = () => {
    window.location.href =
      "https://drive.google.com/file/d/1g5w8LBMn9AJOn6UPrPS6kb9hUKHf5pjs/view?usp=drive_link";
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className=" overflow-hidden">
      <motion.div>
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>

        {decodedVariety === "Agt Wall Panels" && (
          <>
            <div
              className={`new-component md:h-[85vh] h-[60vh] ${zoomIn ? "zoom-in" : "zoom-out"
                } relative`}
              style={{ position: "relative" }}
            >
              <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
              AGT Wall Panels
              </h1>
              <img
                className="overflow-hidden  object-fit md:h-[85vh] h-[60vh] w-screen"
                src={images[currentImageIndex]}
                alt="Agt Wall Panels"
              />
              <div
                className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}
              >
                <Link to={`/products/WallPanels`}>
                  <img
                    src={require("../../../assets/images/project/arrow.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1">
                <Link to="/">Home</Link>/<Link to="/products/WallPanels">Products</Link>/
                <p>WallPanels</p>/<p>AGT Wall Panels</p>
              </div>
            </div>
            <div className="relative w-full pb-[5vh] bg-[#F7F3EA] text-black ">
              <div className="mx-[3vw]">
                <div className="md:max-w-[90%] md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                  AGT is a Turkish company founded in 1984 with the dream that
                  wood can be processed with technology and developed with
                  industrial production. Vikas Plywood partnered with AGT in
                  2018 to become the exclusive all-India dealer for their
                  exquisite wall profiles.
                  <br />
                  <br />
                  These AGT profiles represent an elegant and cost-effective
                  solution for enhancing the aesthetics of your spaces.
                  Exhibiting versatility, they elevate everything from walls and
                  ceilings to doors and wardrobes.
                  <br />
                  <br />
                  Vikas Plywood proudly brings the innovation of AGT to Indian
                  customers, transforming spaces with style and ease. Dive into
                  the various designs and shades of our AGT profiles by
                  exploring our collection below.
                </div>
                <div className="bg-black my-[2vw] h-[1px]" />
                <div className="grid border-2 border-red-500 gap-[2vw] md:grid-cols-2 grid-cols-1 ">
                  <WallComponentLink
                    alt={"AGT 3771"}
                    imagestyle="h-[85vh]"
                    link="wall/agt"
                    motionType="motion2"
                    variety={"3771"}
                    path="wall/1.png"
                  />
                  <WallComponentLink
                    imagestyle="h-[85vh]"
                    link="wall/agt"
                    alt={"AGT 3783"}
                    motionType="motion2"
                    variety={"3783"}
                    path="wall/1.png"
                  />
                  <WallComponentLink
                    imagestyle="h-[85vh]"
                    link="wall/agt"
                    alt={"AGT 2250"}
                    motionType="motion2"
                    variety={"2250"}
                    path="wall/1.png"
                  />
                  <WallComponentLink
                    imagestyle="h-[85vh]"
                    link="wall/agt"
                    alt={"AGT 2050"}
                    motionType="motion2"
                    variety={"2050"}
                    path="wall/1.png"
                  />
                </div>
              </div>
            </div>
            <div className="ml-[5vw]">
              <Gallery images={AGTimages} />
            </div>
            <div className=" mx-[4vw]">
              <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  font-medium mt-[3vw] text-[6vw] md:text-[4vh] text-black">
                Brochure
              </h1>
              <div className="lg:w-[50vw] ">
                <PdfEnquiry
                  imageHeight={"lg:w-[50vw]"}

                  text={"Download the brochure to see why our AGT profiles outshine the ordinary profiles in the market"}
                  category={"wall"}
                  style="none"
                  link="16SXd3GnZW0s74U05cUjgZXAFsr59BtDh"
                  name="Agt Wall Panels"
                  variety={"Wall Panels"}
                />
              </div>
            </div>
            <div className="mx-[4vw] mt-[4vw]">
              <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  font-medium mt-[3vw] text-[6vw] md:text-[4vh] text-black">
                Installation Guide
              </h1>
              <div className="relative w-full  mx-auto">
                <img
                  src={require('../../../assets/images/products/wall/agt/agt.png')}
                  alt="Agt Thumbnail"
                  className="w-full cursor-pointer"
                  onClick={toggleVideo}
                />
                {!showVideo && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="w-20 h-20 text-white fill-current cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      onClick={toggleVideo}
                    >
                      <path d="M3 21v-18l18 9-18 9z"></path>
                    </svg>
                  </div>
                )}
                {showVideo && (
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src="https://www.youtube.com/embed/2eTIiIKFfFg"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>
          </>
        )}

        {showPopup && (
          <FormPopup onClose={closePopup} onSubmit={handleSubmit} />
        )}
        {decodedVariety === "Solid Wood Panels" && (
          <>
            <div
              className={`new-component md:h-[85vh] h-[60vh] ${zoomIn ? "zoom-in" : "zoom-out"
                } relative`}
              style={{ position: "relative" }}
            >
              <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
                {decodedVariety}
              </h1>
              <img
                className="overflow-hidden  object-fit md:h-[85vh] h-[60vh] w-screen"
                src={require(`../../../assets/images/products/wall/${variety}.jpg`)}
                alt="A TV unit decorated with solid wood panels from VIN"
              />
              <div
                className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}
              >
                <Link className="invert"  to={`/products/WallPanels`}>
                  <img 
                    src={require("../../../assets/images/project/arrow.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1">
                <Link to="/">Home</Link>/<Link to="/products/WallPanels">Products</Link>/
                <p>WallPanels</p>/<p>{decodedVariety}</p>
              </div>
            </div>
            <div className="relative w-full pb-[5vh] bg-[#F7F3EA] text-black ">
              <div className="mx-[3vw]">
                <div className=" items-center ">
                  <div className="md:max-w-[90%]  md:text-[3vh] mt-4 font-thin font-['Poppins'] ">
                    Solid wood panels are crafted from solid wood, meaning the
                    entire thickness of the panel is made from genuine wood. It
                    is a popular choice for enhancing the visual appeal of
                    interiors, providing a warm and natural ambience. Their
                    versatility, durability, and timeless appeal make them a
                    favoured option for those seeking the authentic and rich
                    characteristics of natural wood in their designs.
                    <br />
                    <br />
                    Solid wood panelling is often utilised in residential and
                    commercial spaces to add character, texture, and a touch of
                    nature to the surroundings.
                    <br />
                    <br />
                    At Vikas Plywood, we present a diverse range of solid wood
                    panels that meet top-quality standards. Check them out to
                    discover the perfect fit for your needs.
                  </div>
                </div>

                <div className="bg-black my-[2vw] h-[1px]" />
                <h1 className="[font-family:'PP_Hatton-medium',Helvetica] text-center my-[1vw]   font-medium mt-[3vw] text-[6vw] md:text-[4vh] text-black">
                  Natural Wood Panels
                </h1>
                <div className="grid md:grid-cols-3 grid-cols-2 gap-[2vw]">
                  <WallLInk2 alt={"Smoked Alder"} link={"wall/Gallery/VIN_Solid Wood_Smoked Alder.jpg"} variety={"Smoked Alder"} />
                  <WallLInk2 alt={"Walnut"} link={"wall/Gallery/VIN_Solid Wood Panels_Walnut.jpg"} variety={"Walnut"} />
                  <WallLInk2 alt={"Grey Poplar"} link={"wall/Gallery/VIN_Solid Wood Panel_Grey Poplar.jpg"} variety={"Grey Poplar"} />
                  <WallLInk2 alt={"Grey Limba"} link={"wall/Gallery/VIN_Solid Wood Profiles_Grey Limba.jpg"} variety={"Grey Limba"} />
                  <WallLInk2 alt={"Bleached Teak"} link={"wall/Gallery/VIN_Bleached Teak_Profiles.jpg"} variety={"Bleached Teak"} />
                </div>
                <div>
                  <h1 className="[font-family:'PP_Hatton-medium',Helvetica]  font-medium mt-[3vw] text-[6vw] md:text-[4vh] text-black">
                  Brochure
                  </h1>
                  <div className="lg:w-[50vw] ">
                    <PdfEnquiry
                      imageHeight={"lg:w-[50vw]"}
                      category={"wall"}
                      text={"Explore the elegance of natural wood panels from VIN. Select your favourite design and reach out to us"}
                      style="none"
                      link="1hxrvRAU8yccwxdhlkw30XES6QZqWlRB3"
                      name="Solid Wood Panels"
                      variety={"Wall Panels"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-[5vw]">
              <Gallery images={solidimages} />
            </div>
          </>
        )}
      </motion.div>
      <Footer />
    </div>
  );
}
