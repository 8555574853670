import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Gallery from '../Gallery';
import ExteriorEnquiry from './ExteriorEnquiry';
import { Footer } from '../../Footer';
export default function ExteriorComponent() {
  const { variety } = useParams();
  const navigate = useNavigate();

  // const decodedVariety = variety.replace(/_/g, ' ').toUpperCase();
  const decodedVariety = variety.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  const [zoomIn, setZoomIn] = useState(false);
  useEffect(() => {
    setZoomIn(true);
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      setZoomIn(false);
    };

    const unlisten = () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
    window.addEventListener('popstate', handleRouteChange);

    return unlisten;
  }, []);

  const descriptionMap = {
    "deck_flooring": `Deck flooring refers to the material used to construct the surface of an outdoor deck or patio. This flooring provides the walking and recreational surface for outdoor living spaces. It is designed to withstand outdoor conditions, offering a durable and visually appealing surface for relaxation, dining, and entertainment in outdoor environments.

      <br/> <br/>Discover the finest deck flooring options at Vikas Plywood, featuring IPE Deck Flooring and WPC Deck Flooring. Click on ‘Enquire now’ to get started
      `,
    "exterior_cladding": `Exterior cladding is the protective and decorative covering applied to the exterior walls of a building. It serves both aesthetic and functional purposes, enhancing the visual appeal of the structure while also providing protection against weather.

      <br/> <br/>At Vikas Plywood, we present you with a choice of two premium materials for exterior cladding: VIN Teak and WPC Cladding. 
      
      <br/> <br/>Take the first step by clicking ‘Enquire now’ below.
      `,
  };
  const description = descriptionMap[variety] || '';


  const dataMap = {
    "deck_flooring": {
      images: [
        {
          src: require("../../../assets/images/products/exterior/Gallery_Deck Flooring/2.jpg"),
          alt: "IPE deck flooring used installed in home garden"
        },
        {
          src: require("../../../assets/images/products/exterior/Gallery_Deck Flooring/4.jpg"),
          alt: "Java teak used extensively in sauna room"
        },
        {
          src: require("../../../assets/images/products/exterior/Gallery_Deck Flooring/6.jpg"),
          alt: "IPE deck flooring used on rooftop poolside"
        },
        {
          src: require("../../../assets/images/products/exterior/Gallery_Deck Flooring/7.jpg"),
          alt: "IPE deck flooring installed near the entrance of a home garden"
        },
        {
          src: require("../../../assets/images/products/exterior/Gallery_Deck Flooring/8.jpg"),
          alt: "Outdoor decking used on the poolside"
        }

      ]
    }
    ,
    "exterior_cladding": {
      images: [
        { src: require("../../../assets/images/products/exterior/Gallery_Cladding/1.jpg"), alt: "Cladding from Thermory used on the ceiling" },
        { src: require("../../../assets/images/products/exterior/Gallery_Cladding/2.jpg"), alt: "Wood cladding used on the facade of a factory" },
        { src: require("../../../assets/images/products/exterior/Gallery_Cladding/3.jpg"), alt: "Java Teak cladding used near poolside on the rooftop" },
        { src: require("../../../assets/images/products/exterior/Gallery_Cladding/4.jpg"), alt: "WPC cladding in walnut" }
      ]
    }
  };
  const { images } = dataMap[variety] || { images: [] };

  const pdfVarieties = {
    deck_flooring: [
      { name: 'IPE Deck Flooring',alt:"IPE deck flooring used outdoor in the garden" },
      { name: 'WPC Deck Flooring',alt:"WPC deck flooring used in an exterior application" }
    ],
    "exterior_cladding": [
      { name: 'WPC Cladding',alt:"WPC cladding" },
      { name: 'VIN Teak',alt:"Java teak blocks in square format" }

    ],
  };

  const selectedimages = pdfVarieties[variety] || [];
  return (
    <div>
      <motion.div
      >
        <div className={`z-[100] `}>
          <Header logo="logomenu.png" color="black" />
        </div>
        <div className={`new-component md:h-[85vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
          <h1 className="text-white whitespace-nowrap absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
            {decodedVariety}
          </h1>
          <img
            className="overflow-hidden  object-cover md:h-[85vh] h-[60vh] w-screen"
            src={require(`../../../assets/images/products/exterior/${variety}.jpg`)}
            alt={decodedVariety}
          />
          <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
            <Link className='invert' onClick={() => navigate(-1)} to={`/products/ExteriorMaterials`} >
              <img src={require("../../../assets/images/project/arrow.png")} alt="" />
            </Link>
          </div>
          <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
            <Link to="/">
              Home
            </Link>
            /
            <Link to="/products/ExteriorMaterials">
              Products
            </Link>
            /
            <p >
              Exterior <span className='max-md:hidden'>Materials</span>
            </p>
            /
            <p  >
              {decodedVariety}
            </p>
          </div>
        </div>
        <div className="relative w-full pb-[5vh]  bg-[#F7F3EA] text-black ">
          <div className='mx-[3vw]' >
            <div className="lg:max-w-[80%] border border-black md:text-[3vh] font-thin font-['Poppins'] my-10" dangerouslySetInnerHTML={{ __html: description }} />

            <div className='grid items-center lg:grid-cols-2'>
              {selectedimages.map((image, index) => (
                <ExteriorEnquiry category={decodedVariety}
                  key={index} alt={image.alt} images={image.name} />
              ))}
            </div>
          </div>
          <div className='grid ml-[3vw] overflow-hidden '>
            <Gallery images={images} />
          </div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
}
