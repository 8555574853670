import { motion } from "framer-motion";
import { useParams, Link } from "react-router-dom";
import Header from "../Navbar/Navbar";
import { Footer } from "../Footer";
import { AnimatedComparisonTable, AnimatedSection, AnimatedText, AnimatedTitle, data, FullScreenParallax, HeroSection, ParallaxSection, points2, points3, points4, points5, ScrollButton } from "./storiesData";
import StoriesInside from "./StoriesInside";

export default function NewComponent() {
  const { variety } = useParams();
  const getTitleByVariety = {
    "Enhancing_Your_Home": "Enhancing Your Home With Exquisite Laminated Wooden Flooring",
    "Veneer_vs_Laminate": "Veneer vs Laminate: Understanding the Distinctions",
    "AGT_Wall_Panels": "AGT Wall Panels: Outshining Industry Alternatives with Unrivaled Quality",
    "Why_Pre-polished_Veneers_are_the_future": "Why Pre-polished Veneers are the future?",
    "VIKAS_PLYWOOD_The_Wooden_Wonderland": "VIKAS PLYWOOD: The Wooden Wonderland",
    "The_8-Step_Modular_Process_at_Vikas_Plywood": "The 8-Step Modular Process at Vikas Plywood"
  };

  const getDecodedTitle = (variety) => {
    return getTitleByVariety[variety] || variety.split("_").map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(" ");
  };

  const decodedVariety = getDecodedTitle(variety);
  
  const BackButton = () => (
    <Link to="/stories" className="absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0">
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.7 }}
      >
        <img
          src={require("../../assets/images/project/arrow.png")}
          alt="Back"
          className={variety === "VIKAS_PLYWOOD_The_Wooden_Wonderland" || variety === "Enhancing_Your_Home" ? "invert" : "invert"}
        />
      </motion.div>
    </Link>
  );

  return (
    <div className="overflow-hidden bg-[#F7F3EA]">
      <div className="z-[100] fixed w-full">
        <Header logo="logo.png" color="white" />
      </div>


      <HeroSection variety={variety} decodedVariety={decodedVariety}>
  <BackButton />
  <ScrollButton />
</HeroSection>
      {variety === "Enhancing_Your_Home" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Enhancing Your Home With Exquisite Laminated Wooden Flooring
              </h1>
              <p className="font-['Poppins'] font-light md:text-[1.6vw] text-lg">
                Laminated flooring has become an increasingly popular choice for homeowners who desire not only durability but also a touch of timeless elegance in their living spaces. From the living room to the bedroom, the warm and inviting nature of laminate floors adds an aura of sophistication that is hard to match with any other flooring material.
              </p>
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium my-6">
                Why Choose Laminated Wooden Flooring?
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Here are some compelling reasons why you should consider choosing laminated wooden flooring for your next project:
              </p>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
  <table className="w-full border-collapse">
    <tbody>
      {points2.map((point, index) => {
        const [title, ...content] = point.split(': ');
        
        return (
          <tr key={index}>
            <td className="px-4 py-2 [font-family:'PP_Hatton-medium',Helvetica] align-top">•</td>
            <td className="px-4 py-2">
              <span className="font-semibold text-xl lg:text-[1.5vw] [font-family:'PP_Hatton-medium',Helvetica]">{title}:</span>
              <span className="font-['Poppins'] ml-2 lg:text-[1.3vw] font-light">{content.join(': ')}</span>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/Kronotex India_Vikas Plywood.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                The Aesthetic Appeal of Laminated Wooden Floors
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Wooden flooring can seamlessly blend with various interior designs, enhancing the visual appeal of your home. Whether you prefer a classic, rustic look or a modern, minimalist style, wooden floors can adapt to your vision.
              </p>
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium my-6">
                Popular Wood Choices
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Some popular wood choices available in the industry are:
              </p>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
  <table className="w-full border-collapse">
    <tbody>
      {points3.map((point, index) => {
        const [title, ...content] = point.split(': ');
        
        return (
          <tr key={index}>
            <td className="px-4 py-2 [font-family:'PP_Hatton-medium',Helvetica] align-top">•</td>
            <td className="px-4 py-2">
              <span className="font-semibold text-xl lg:text-[1.5vw] [font-family:'PP_Hatton-medium',Helvetica]">{title}:</span>
              <span className="font-['Poppins'] ml-2 lg:text-[1.3vw] font-light">{content.join(': ')}</span>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</AnimatedSection>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/Kronotex_Vikas.webp")}
              height="md:h-[70vh] h-[40vh] "
            />
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/Dynamic Series_Kronotex_Vikas Plywood.webp")}
              height="md:h-[70vh] h-[40vh]"
            />
          </div>

            <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Installation and Maintenance
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                To ensure the longevity and beauty of your floors, consider the following maintenance tips:
              </p>
            </div>
  <table className="w-full border-collapse">
    <tbody>
      {points4.map((point, index) => {
        const [title, ...content] = point.split(': ');
        
        return (
          <tr key={index}>
            <td className="px-4 py-2 [font-family:'PP_Hatton-medium',Helvetica] align-top">•</td>
            <td className="px-4 py-2">
              <span className="font-semibold text-xl lg:text-[1.5vw] [font-family:'PP_Hatton-medium',Helvetica]">{title}:</span>
              <span className="font-['Poppins'] ml-2 lg:text-[1.3vw] font-light">{content.join(': ')}</span>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Conclusion
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                In conclusion, laminated wooden flooring is a timeless and versatile choice that can transform your home into a welcoming and elegant space. With its durability, easy maintenance, affordability and aesthetic appeal, they are an investment that not only adds value to your property but also enriches your daily living experience.
              </p>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light mt-4">
                Vikas Plywood offers a diverse selection of <Link className="text-blue-600" to="/products/WoodenFlooring/laminated_wooden_flooring">laminate flooring</Link> options tailored to suit various preferences. Whether you desire the flawless replication of wood grain or the genuine sensation of stone textures, we provide the ideal laminate flooring solution to meet any space or need. As the authorised reseller for Kronotex Germany throughout Telangana, we ensure access to high-quality products and exceptional customer service. <Link className="text-blue-600" target="_blank" to="https://wa.me/+917093573311">Get in touch today!</Link>
              </p>
            </div>
          </AnimatedSection>
        </div>
      )}
      {variety === "Veneer_vs_Laminate" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Veneer vs Laminate: Understanding the Distinctions
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                When it comes to enhancing the look of furniture, cabinets, or countertops, <Link to="/products/Veneers" className="text-blue-600">veneer</Link> and <Link to="/products/Laminates" className="text-blue-600">laminate</Link> are two popular choices. They both offer cost-effective alternatives to solid wood or stone surfaces, but they differ significantly in terms of materials, appearance, and applications.
              </p>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
  <AnimatedComparisonTable data={data} />
</AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Conclusion
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                In conclusion, the choice between veneer and laminate depends on your specific needs and preferences. Veneer adds a touch of natural beauty, while laminate offers versatility and durability. Assess the demands of your project and your desired aesthetic to make the best decision for your furniture, cabinets, or surfaces.
              </p>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light mt-4">
                The curated collection and experienced team at Vikas Plywood are here to guide you in making the optimal selection. Reach out to us today to make the right choice for your needs.
              </p>
            </div>
          </AnimatedSection>
        </div>
      )}

      {variety === "AGT_Wall_Panels" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                AGT Wall Panels: Outshining Industry Alternatives with Unrivaled Quality
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                In the ever-evolving world of interior design, wall paneling has emerged as a transformative element, seamlessly blending functionality with aesthetic appeal. As homeowners and architects alike seek to create spaces that reflect their unique style, the quest for quality wall paneling solutions has become paramount. This pursuit led us to <Link target="_blank" to="https://www.agt.com.tr/en" className="text-blue-600">AGT</Link>, a brand that stands as a paragon of excellence in the realm of interior paneling.
              </p>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                The Quest for Quality
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                When it comes to wall paneling solutions, not all products are created equal. In a market flooded with cheap imports from China and India, it's crucial to prioritize quality over cost-cutting measures that can compromise safety and durability. This is where AGT, a leading Turkish manufacturer, raises the bar with its exceptional decorative panel offerings.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog3_2.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                The Perils of Hazardous Alternatives
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Many budget alternatives, such as charcoal panels, harbor a dark secret – the presence of toxic chemicals like lead and cyanide. These harmful substances can spread into the living spaces where you live, posing severe health risks and potentially causing organ diseases. Prioritizing cost savings at the expense of well-being is never a wise trade-off.
              </p>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                A Paragon of European Excellence
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                AGT wall panels are a testament to European craftsmanship and adherence to stringent quality standards. Manufactured in state-of-the-art facilities, these panels boast a perfect surface structure. Every panel is a masterpiece of superior quality and assurance.
              </p>
            </div>
          </AnimatedSection>

         
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/blog3_1.webp")}
              height="md:h-[60vh] h-[50vh]"
            />
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/blog3_4.webp")}
              height="md:h-[60vh] h-[50vh]"
            />
          </div>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Unwavering Strength and Durability
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                At the core of AGT's wall panels lies a secret weapon – High-Density Fiberboard (HDF) backing. This robust material ensures maximum resistance to bending and swelling, providing a solid foundation that withstands the test of time. Additionally, the high-quality PVC wrap offers exceptional durability and water resistance, safeguarding against wear and tear caused by moisture and chemical cleaners.
              </p>
            </div>
          </AnimatedSection>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/agt1.webp")}
              height="md:h-[65vh] h-[50vh]"
            />
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/agt2.webp")}
              height="md:h-[65vh] h-[50vh] "
            />
          </div> */}
           <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog3_5.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-2/3">
                <div className="prose max-w-none">
                  <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                    A Commitment to Healthier Living
                  </h1>
                  <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                    AGT's dedication to quality extends beyond mere aesthetics; it encompasses a profound commitment to promoting healthier living environments. These profiles proudly bear the Greenguard Certification, a prestigious accolade that signifies their active contribution to creating healthier homes and a better future for all.
                  </p>
                </div>
              </div>
              <div className="md:w-1/3 mt-8 md:mt-0">
                <img
                  src={require("../../assets/images/stories/Pictures/agt5.webp")}
                  alt="AGT Certification"
                  className="max-h-[30vh] mx-auto"
                />
              </div>
            </div>
          </AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Conclusion
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                In the realm of interior paneling solutions, AGT stands as a beacon of excellence, prioritizing quality, beauty, and durability along with zero hazardous toxins. By choosing AGT, you're not only adorning your spaces with beauty; you're also investing in a future where superior craftsmanship and ethical practices reign supreme.
              </p>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light mt-4">
                Explore the <Link target="_blank" to="https://www.vikasplywood.com/products/WallPanels/agt_wall_panels" className="text-blue-600">AGT collection</Link> today, only at Vikas Plywood.
              </p>
            </div>
          </AnimatedSection>
        </div>
      )}
      {variety === "Why_Pre-polished_Veneers_are_the_future" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Why Should You Use Pre-Polished Veneers by VIN?
              </h1>
              <h2 className="text-3xl font-medium mb-6">
                A Cut Above the Rest
              </h2>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                When it comes to transforming your space, veneers offer a world of possibilities. But not all veneers are created equal. Pre-polished veneers by VIN stand out as a game-changer in interior design, offering a perfect blend of elegance, durability, and convenience.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/polish1.webp")}
            height="100vh"
          />

           

            <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                What Makes Pre-Polished Veneers Superior?
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Unlike traditional veneers that require additional polishing after installation, pre-polished veneers come ready-to-use, boasting a flawless finish straight out of the box. This translates to:
              </p>
            </div>
  <table className="w-full border-collapse">
    <tbody>
      {points5.map((point, index) => {
        const [title, ...content] = point.split(': ');
        
        return (
          <tr key={index}>
            <td className="px-4 py-2 [font-family:'PP_Hatton-medium',Helvetica] align-top">•</td>
            <td className="px-4 py-2">
              <span className="font-semibold text-xl lg:text-[1.5vw] [font-family:'PP_Hatton-medium',Helvetica]">{title}:</span>
              <span className="font-['Poppins'] ml-2 lg:text-[1.3vw] font-light">{content.join(': ')}</span>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</AnimatedSection>

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-6">
                Experience the VIN Difference
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                At VIN Veneers, we pride ourselves on being Hyderabad's leading provider of <Link className="text-blue-600" to="/products/Veneers/pre-polished_veneers">pre-polished veneers</Link>. Our commitment to excellence is reflected in every sheet we produce. We understand that your space is a reflection of your style, and our veneers are designed to elevate your interior to new heights.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/poliish5.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Whether you're designing a modern living room, a comfortable bedroom, or a prestigious office, VIN veneers will add a touch of magnificence to any space.
                <br /><br />
                <Link target="_blank" to="https://www.google.com/maps/place/Vikas+Plywood+and+Veneers/@17.4303471,78.4040618,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb9733fea07e7f:0x4a00526f53c154e2!8m2!3d17.4303471!4d78.4040618!16s%2Fg%2F1jkw2p1ps?entry=ttu" className="text-blue-600">Visit our showroom</Link> in Hyderabad or <Link to="/contact" className="text-blue-600">contact us</Link> today to explore our collection of pre-polished veneers.
              </p>
            </div>
          </AnimatedSection>
        </div>
      )}

      {variety === "VIKAS_PLYWOOD_The_Wooden_Wonderland" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
                VIKAS PLYWOOD: The Wooden Wonderland
              </h1>
              <p className="text-sm font-['Poppins'] font-extrabold opacity-40 mb-6">
                By Piyush Mehra (Principal Architect, PS Design)
              </p>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                As the sun rolls towards obtusity, the southwest rays percolate the wooden matrix to create a mesmerizing effect on the insides of this space called Vikas Plywood.
                <br /><br />
                Located in the Jubilee Hills neighborhood of Hyderabad, the showroom is the Head Office and display center for one of the city's oldest wood material suppliers – Vikas Plywood. The legacy of the company goes back to 1975.
                <br /><br />
                The idea of the space was to start with an impactful façade which imbibes the true spirit of the company – as it lives and breathes wood. The façade is a simple arrangement of 4"x4" square cut pieces of an outdoor wooden cladding product named Java Teak. The pieces are cut from a bigger plank – and staggered in the mix to randomize the pattern and avoid running veins. The arrangement is then neatly dry-cladded on the façade with equal spacing and a vertical angular surface for a more welcoming effect. The pattern also protects the showroom from direct south-west sun exposure, thereby reducing the heat load substantially.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog5_1.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Upon entering, the space greets you with a life-size tree mural on the wall housing the company's legacy accompanied by the story of Vikas Plywood. On the opposite face is a floating reception table with only one central support. The table is engineered in a way that the load-beating tensile base is embedded under the finished flooring, thereby giving the table a floating effect. A plain light oak veneer cladding, with the company logo etched into the surface, becomes the backdrop of the reception. Moving forward looking towards the inside of the studio is a wooden screen with resin blocks acting as a visual barrier. The clear resin blocks are embedded with wooden shavings, signifying the strong ethos of the brand along with the partner brands under the main Brand's umbrella.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-6">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                The space opens up to a massive double height lounge with a wooden wall feature which extends from the wall to the ceiling in a curved shape. This cladding is done in order to tie up the massive height into one.
              </p>
            </div>
          </AnimatedSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/Vikas Plywood ED-35.webp")}
              height="md:h-[70vh] h-[40vh] "
            />
            <ParallaxSection
              src={require("../../assets/images/stories/Pictures/Vikas Plywood ED-67.webp")}
              height="md:h-[70vh] h-[40vh]"
            />
          </div>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                The showroom is divided into two floors. The ground floor showcases the brand's flagship products – Wooden Flooring, Laminate Flooring, Wall Claddings, Veneer Sheets, Laminate selections, Outdoor Cladding products, Soundproof cladding products, etc. Each product department is given a separate zone in order to maintain a free flow inside the showroom. At any given point in time, if different sets of clients are looking at different products, there is minimum hindrance. The admin section with staff seating and the MD cabin are centrally located so as to attain transparent access to the incoming clients.
              </p>
            </div>
          </AnimatedSection>
          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/Vikas Plywood ED-71.webp")}
            height="100vh"
          />
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                There is a massive loose-products area with pull-out wall storage units that house the big sized catalogs. A big marble table is stationed between the pull-outs in order to view the catalogs in the same place and immediately put them back. This allows the space to be clutter free. Similarly, there is a separate zone for wooden wall cladding products, flooring (solid wood and laminate separately). The space for Veneer display is on the far right corner of the showroom in order to allow easy access for new material movement through a service entrance.
              </p>
            </div>
          </AnimatedSection>
          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog5_2.jpg")}
            height="100vh"
          />
        
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Vertical circulation was to play a vital role in the design of the showroom since 50% of the products are displayed on the upper level. Therefore, a biomorphic sculptural staircase was designed that works both as an eyepiece and also invites the users to climb up. The parametrically designed staircase is made using layers of Birch plywood forming a breathing handrail profile which goes from wider to narrower and again opens up as it twists upwards on the right-hand side. The staircase is complemented with a solid-colour vertical cladding done in order to give the free-flowing staircase a sense of structure around it.
              </p>
            </div>
          </AnimatedSection>
          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog5_3.jpg")}
            height="100vh"
          />
         
        
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                The left half is dedicated to beautiful kitchen settings, housing medium to large sized kitchens. The space ends into a large material library for easy selection of finishes. The right half contains wardrobe mockups with 2 massive walkin arrangements, open wardrobe settings, innovative shutter ideas, and also two office cabins.
              </p>
            </div>
          </AnimatedSection>
          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog5_4.jpg")}
            height="100vh"
          />
          

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
                Vikas Plywood is an odyssey written with the ink of wood and the emotions it imbibes. The space is composed like a musical symphony wherein the individual notes are the practical elements which conduct themselves in to create a Perfect Symmetry of Design.
              </p>
            </div>
          </AnimatedSection>
          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog5_5.jpg")}
            height="100vh"
          />
        </div>
      )}
      {variety === "The_8-Step_Modular_Process_at_Vikas_Plywood" && (
        <div className="  mx-auto">
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              The 8-Step Modular Process at Vikas Plywood
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              When building your dream home, selecting the perfect kitchen and wardrobes is essential for achieving the desired functionality, style, and enduring quality. At Vikas Plywood, we recognize this importance and offer a range of modular kitchen and wardrobe solutions. Our seamless process, from initial consultation to final installation, ensures that your vision is transformed into a beautiful and functional reality.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog_6_1.webp")}
            height="100vh"
          />
          
<AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 1 - Understanding your needs

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              Every dream starts with an idea. Your journey with us begins with a visit to our showroom. This is where we get to know you. We discuss your lifestyle, your aesthetic preferences, and, most importantly, your needs for your kitchen and wardrobes. What are your must-haves? What are your design inspirations? This initial consultation is crucial for understanding the scope of your project and setting the stage for a successful collaboration.
              </p>
            </div>
          </AnimatedSection>
         
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 2 - Bringing your vision to life
              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              Based on our initial discussion, we'll present you with initial drawings. These drawings give you a visual representation of how your kitchen or wardrobe will look, incorporating your ideas and our expertise. This is your chance to provide feedback and make any necessary adjustments. We want to ensure you’re excited about what you are seeing.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 3 - The details that matter

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              Once you’re happy with the initial design, we delve into the specifics. We'll discuss the final finishes, exploring the wide range of options available with us. From Italian laminates to elegant veneers, we'll help you choose the perfect materials to match your style and budget. We'll also finalize the internal divisions of your cabinets and wardrobes, maximizing storage and functionality. This is where your dream truly starts to take shape.
              </p>
            </div>
          </AnimatedSection>

          <FullScreenParallax
            image={require("../../assets/images/stories/Pictures/blog_6_2.webp")}
            height="100vh"
          />

          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 4 - Ensuring perfection

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              With the design and finishes finalized, we conduct a site visit. This crucial step allows us to assess the site conditions and take precise measurements. This ensures that your modular kitchen and wardrobes fit perfectly into your space, avoiding any last-minute surprises.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 5 - Preparing the blueprint, aka production drawings

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              Following the site visit, our team creates detailed production drawings. These drawings are the blueprint for your project, containing all the specifics: finishes, hardware, precise dimensions, and internal divisions. We leave no stone unturned, ensuring that every detail is accounted for.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 6 - Final stamp of approval

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              Before production begins, we'll have a final discussion to review the production drawings with you. This is your last opportunity to make any changes and ensure that everything is exactly as you envision it. Once you’ve signed off on the drawings, we move into the production phase.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 7 - Production and installation

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              From the sign-off date, our dedicated team will take approximately 60 days to meticulously craft your modular kitchen and wardrobes. We use high-quality materials from Vikas Plywood and employ skilled craftsmen to ensure superior quality and durability. Once the production is complete, our expert installers will carefully assemble and install your new kitchen and wardrobes in your home.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium mb-2">
              Step 8 - Quality check and handover

              </h1>
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
              After installation, we'll conduct a thorough review and quality check with you. We want to ensure that you are completely satisfied with every aspect of the project. Once you've given your final sign-off, we'll hand over your dream kitchen and wardrobes, ready to be enjoyed for years to come.
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
              {/* <h1 className="text-4xl  lg:text-[3vw] [font-family:'PP_Hatton-medium',Helvetica] font-medium">
              Full process image:
              </h1> */}
              <img
          src={require("../../assets/images/stories/Pictures/blog_6_4.png")}
          alt="Back"
          className="w-[90vw] max-md:hidden mx-auto"
        />
         <img
          src={require("../../assets/images/stories/Pictures/blog_6_3.png")}
          alt="Back"
          className="w-[90vw] mt-2 md:hidden mx-auto"

        />
            </div>
          </AnimatedSection>

         






          <AnimatedSection className="my-16">
            <div className="prose max-w-none">
             
              <p className="font-['Poppins'] md:text-[1.6vw] text-lg font-light">
            
              Over the years we have had the privilege of working with esteemed clients across India, including names like Providence, EA Sports, and DE Shaw among a few corporates and numerous high-net-worth individuals, delivering exceptional quality and craftsmanship every time. We provide the right foundation for crafting your dream <Link to="/products/ModularInteriors/kitchens" className="text-blue-600">kitchen</Link> or <Link to="/products/ModularInteriors/wardrobes" className="text-blue-600">wardrobe</Link>, ensuring a space you'll enjoy for years to come.
<br/>
<Link to="/contact" className="text-blue-600">Contact us</Link> today for a free consultation! Let us help you transform your living space with our diverse options and installation services. 
              </p>
            </div>
          </AnimatedSection>
        </div>
      )}


<StoriesInside/>
      <Footer />
    </div>
  );
}
