import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../../Navbar/Navbar';
import { useParams,  Link, useNavigate } from 'react-router-dom';
import Enquiry from '../Enquiry';
import { Footer } from '../../Footer';
export default function PlywoodComponent({ }) {
    const navigate = useNavigate();

    const { image, variety, category } = useParams();
    // const decodedVariety = varietyreplace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())
    const [zoomIn, setZoomIn] = useState(false);
    useEffect(() => {
        setZoomIn(true);
    }, []);
    useEffect(() => {
        const handleRouteChange = () => {
            setZoomIn(false);
        };

        const unlisten = () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
        window.addEventListener('popstate', handleRouteChange);

        return unlisten;
    }, []);

    const descriptionMap = {
        "hardwood_(mr-grade)": `Hardwood also known as MR-grade plywood or Commercial plywood is the most commonly used plywood in the construction industry. <br /> This is moisture-resistant, meaning it can resist moisture and normal levels of water but is not waterproof. `,
        "boiling_water_proof_(bwp)": `Boiling Waterproof plywood or BWP plywood is highly resistant to water which makes it waterproof.<br /> It is one of the strongest plywood in the industry deriving its strength from the number of plies and other high-quality raw materials which are used in the manufacturing of these plywood sheets.`,
        "gurjan_plywood":`Gurjan is a high-quality plywood made from the wood of the Gurjan tree, native to Southeast Asia.<br /> It is one of the toughest and most durable plywood options in the industry, known for its high water resistance and heat resistance, making it suitable for various applications where robust and durable materials are required.`,
        "birch_plywood":`Birch plywood comes from the lush Baltic forests of Russia.<br /> It is an engineered wood product made from layers of birch wood veneers.<br /> It stands as the standard bearer in the plywood market due to its highest water resistance, rendering it completely waterproof.<br /> Additionally, it is the most visually appealing and versatile type of plywood offering excellent strength and durability.`,
    };
    const description = descriptionMap[variety] || '';

    const dataMap = {
        "hardwood_(mr-grade)": {
            points: [
                " It is moisture-resistant but not waterproof",
                " It resists termites and borers to an extent",
                "It is best suited for commercial and residential purposes",
                "It is most preferred in warm and humid conditions",
                "It is the most economical type of plywood"
            ],
            images: [
                "GreenPly MR-grade",
                "CenturyPly - Sainik MR-grade",
                "Mikasa MR+",
                "Austin MR-grade",
                "Hunsply-MR grade"
            ]
        },
        "boiling_water_proof_(bwp)": {
            points: [
                "It is semi-waterproof.",
                "It has a higher resistance to termites and borer",
                "It is best suited for high-end residential properties",
                "It is suitable for certain applications where exposure to moisture may occur like the kitchen and other wet places",
                "It is costlier than MR-grade hardwood due to the extra benefits it offers",
            ],
            images: [
                "GreenPly BWP 710",
                "CenturyPly - Sainik 710",
                "Hunsply-BWR-18",
                "Austin BWP 710",
                "VIN Ply"
            ]
        }
        ,
        "gurjan_plywood": {
            points: [
                "It has excellent durability and strength",
                "It is fire retardant",
                "It has a higher resistance to termites and borers as compared to MR-grade hardwood and BWP",
                "It is most preferred in kitchens and other wet places due to its fire-safe and water-resistance abilities",
                "It is a premium product making it costlier than MR-grade hardwood and BWP",
            ],
            images: [
                "Green Club 700 Gurjan",
                "CenturyPly- ClubPrime - Gurjan",
                "Mikasa Fire Guardian",
                "Austin Club",
                "Hunsply Gurjan",
                "VIN Ply"
            ]
        }
        ,
        "birch_plywood": {
            points: [
                "It is waterproof",
                "It has immense dimensional stability preventing it from warping or twisting when exposed to changes in temperature",
                "It is renowned for its impressive strength and durability",
                "It provides the highest resistance to termites and borers compared to other types of plywood",
                "It is preferred for applications where aesthetics are crucial like furniture, cabinetry, and craft projects. It can also be polished to enhance its visual appeal",
            ],
            images: [
                "VIN Birch plywood",
            ]
        }
    };
    const { points, images  } = dataMap[variety] || { points: [], images: [] };
    return (
        <div>
            <motion.div
            >
                <div className={`z-[100] `}>
                    <Header logo="logomenu.png" color="black" />
                </div>
                <div className={`new-component md:h-[85vh] h-[60vh] ${zoomIn ? 'zoom-in' : 'zoom-out'} relative`} style={{ position: 'relative' }}>
                    <h1 className="text-white whitespace-nowrap absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 flex items-center justify-center [font-family:'PP_Hatton-medium',Helvetica] font-medium text-[6vw] md:text-[6vh]">
                    <p  >
                            {variety==="hardwood_(mr-grade)" &&(
                                <>
                                Hardwood (MR-Grade)
                                </>
                            )}
                             {variety==="boiling_water_proof_(bwp)" &&(
                                <>
                                Boiling Water Proof (BWP)
                                </>
                            )}
                             {variety==="gurjan_plywood" &&(
                                <>
                                Gurjan Plywood
                                </>
                            )}
                             {variety==="birch_plywood" &&(
                                <>
                                Birch Plywood
                                </>
                            )}
                        </p>
                    </h1>
                    <img
                        className="overflow-hidden  object-cover  md:h-[85vh] h-[60vh] w-screen"
                        src={require(`../../../assets/images/products/plywood/${variety}.jpg`)}
                        alt="selected story"
                    />
                    <div className={`absolute bottom-0 max-md:w-[100px] z-40 md:m-10 m-[3vw] left-0`}>
                        <Link onClick={() => navigate(-1)} >
                            <img className=' invert'  src={require("../../../assets/images/project/arrow.png")} alt="" />
                        </Link>
                    </div>
                    <div className='flex absolute text-white bottom-5 md:bottom-0 m-[3vw] right-1 text-[4vw] md:text-[3vh]  gap-1'>
                        <Link to="/">
                            Home
                        </Link>
                        /
                        <Link to="/products/Plywood">
                            Products
                        </Link>
                        /
                        <p >
                        Plywood
                        </p>
                        /
                        <p  >
                            {variety==="hardwood_(mr-grade)" &&(
                                <>
                                Hardwood (MR-Grade)
                                </>
                            )}
                             {variety==="boiling_water_proof_(bwp)" &&(
                                <>
                                Boiling Water Proof (BWP)
                                </>
                            )}
                             {variety==="gurjan_plywood" &&(
                                <>
                                Gurjan Plywood
                                </>
                            )}
                             {variety==="birch_plywood" &&(
                                <>
                                Birch Plywood
                                </>
                            )}
                        </p>
                    </div>
                </div>
                <div className="relative w-full pb-[5vh]  bg-[#F7F3EA] text-black ">
                    <div className='mx-[3vw]' >
                        <div className="lg:max-w-[80%] border border-black md:text-[3vh] font-thin font-['Poppins'] my-10" dangerouslySetInnerHTML={{ __html: description }} />
                        <div className='bg-black h-[1px]' />
                        <h1 className="md:text-[3vh] font-medium font-['Poppins'] my-[2vw] ">Key points to note</h1>
                        <div className="md:w-[60vw] flex">
                            <table className="w-full mb-[2vw] font-thin font-['Poppins']   border-collapse  md:text-[1.5vw]">
                                <tbody>
                                    {points.map((point, index) => (
                                        <tr key={index} className=" hover:bg-gray-50">
                                            <td className="border-b border-solid border-black px-4 py-2">{point}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className='grid border-2 border-red-500 md:grid-cols-2 grid-cols-1'>
                            {images.map((img, index) => (
                                <Enquiry key={index} image={img} />
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
            <Footer/>
        </div>
    );
}
